import React from 'react';
import { NavLink } from 'react-router-dom';
import "../styles/NavBar.css";




const NavBar = () => {
	const image =require.context('../assets/logo y otros',true);
	return (
		<div>

<nav className="navbar n-row header-row header-row-fix fixed navbar-dropdown collapsed opened">
   
    <div className="logo"><a href="/"> 
					<img className='image-m'
        src="https://encrypted-tbn1.gstatic.com/images?q=tbn:ANd9GcQz06XG4pjiQcL--ms9pvjFW1Y-VF5iRinpaxR7tZ2eqVf5ypOv"
        alt=""
      />
	  <img className='image-m'
        src={image('./logo alvasa.png')}
        alt=""
      />
					</a></div>

    
    <ul className="nav-links ">

      
      <input type="checkbox" id="checkbox_toggle" />
      <label for="checkbox_toggle" className="hamburger">&#9776;</label>

      
      <div className="menu">
		

	  
    <li><a class="active" href="#">Inicio</a></li>		
						
    <li><a class="active" href="#oficinas">Locales</a></li>					
    <li><a class="active" href="#disponible">En Alquiler</a></li>		
    <li><a class="active" href="#contact">Contacto</a></li>				
												
          
      </div>
    </ul>
  </nav>
		
		</div>
	);
};

export default NavBar;