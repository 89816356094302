import React from 'react';
import { MDBFooter, MDBContainer, MDBRow, MDBCol, MDBIcon } from 'mdb-react-ui-kit';
import { NavLink } from 'react-router-dom';
export default function Footer() {
  
  return (
    <MDBFooter bgColor='light' className='text-center text-lg-start text-muted'>
      <section className='d-flex justify-content-center justify-content-lg-between p-4 border-bottom'>
        <div className='me-5 d-none d-lg-block'>
       
        </div>

        <div>
          <a href='https://www.facebook.com/tournoncr/?locale=es_LA' className='me-4 text-reset'>
            <MDBIcon color='secondary' fab icon='facebook-f' />
          </a>
       
          <a href='https://www.instagram.com/tournoncr/' className='me-4 text-reset'>
            <MDBIcon color='secondary' fab icon='instagram' />
          </a>
       
          <a href='https://ul.waze.com/ul?ll=9.94370053%2C-84.07593369&navigate=yes&zoom=17&utm_campaign=default&utm_source=waze_website&utm_medium=lm_share_location' className='me-4 text-reset'>
            <MDBIcon color='secondary' fab icon='waze' />
          </a>
        
        </div>
      </section>

      <section className='footer'>
        <MDBContainer className='text-center text-md-start mt-5'>
          <MDBRow className='mt-3'>
            <MDBCol md='3' lg='4' xl='3' className='mx-auto mb-4'>
              <h6 className='text-uppercase fw-bold mb-4'>
                <MDBIcon color='secondary' icon='gem' className='me-3' />
                Centro Comercial Tournón 
                  - Edificio Alvasa
              </h6>
              <p>
               Complejo Comercial y Oficinas
              </p>
            </MDBCol>

            <MDBCol md='2' lg='2' xl='2' className='mx-auto mb-4'>
              <h6 className='text-uppercase fw-bold mb-4'>Oficinas</h6>
              <p>
              <NavLink to="/mtss">MTSS</NavLink>
  
              </p>
              <p>
              <NavLink to="/da">Dirección de Agua</NavLink>
              </p>
              <p>
              <NavLink to="/full">Full Media</NavLink>
              </p>
              <p>
              <NavLink to="/sierra">Sierra Consultores Legales</NavLink>
              </p>
               <p>
              <NavLink to="/ucsmart">uCBusiness</NavLink>
              </p>
              <p>
              <NavLink to="/tres">360 Servicios Corporativos</NavLink>
              </p>
            </MDBCol>

            <MDBCol md='3' lg='2' xl='2' className='mx-auto mb-4'>
              <h6 className='text-uppercase fw-bold mb-4'>Locales Comerciales</h6>
              <p>
              <NavLink to="/sueños">Los Sueños Café</NavLink>
              </p>
              <p>
              <NavLink to="/arroyito">Eventos Arroyito</NavLink>
              </p>
              <p>
              <NavLink to="/gyg">G & G Medical</NavLink>
              </p>
              <p>
              <NavLink to="/cora">Cora Medical</NavLink>
              </p>
              <p>
              <NavLink to="/brothers">Brothers CR</NavLink>
              </p>
              <p>
              <NavLink to="/homm">Homm by Karla</NavLink>
              </p>
              <p>
              <NavLink to="/sinap">Sinapsis</NavLink>
              </p>
            </MDBCol>

            <MDBCol md='4' lg='3' xl='3' className='mx-auto mb-md-0 mb-4'>
              <h6 className='text-uppercase fw-bold mb-4'>Contacto</h6>
              <p>
                <MDBIcon color='secondary' icon='home' className='me-2' />
                Costa Rica, San José Barrio Tournon
              </p>
              <p>
                <MDBIcon color='secondary' icon='envelope' className='me-3' />
                info@tournoncr.com
              </p>
              <p>
                <MDBIcon color='secondary' icon='phone' className='me-3' /> + 506 6045-5149
              </p>
           
            </MDBCol>
          </MDBRow>          
        </MDBContainer>
      </section>

      <div className='text-center p-4' style={{ backgroundColor: 'rgba(0, 0, 0.1, 0.06)' }}>
        © 2023 Copyright:
        <a className='text-reset fw-bold' href='https://parwebcr.com/'>
          Tournoncr.com
        </a>
      </div>
      
    </MDBFooter>
  );
}